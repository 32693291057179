import React from 'react'
import { Link } from 'gatsby'
import './shop.css'

import Img from 'gatsby-image'

export default ({ product }) => (
    <div className="product">
        <div className="productContainer">
            <Link to={`/produits/${product.slug}`}>
                <div className="productOverlay">
                    <Img fluid={product.heroImage[0].fluid} alt={product.productName} className="productImage" />
                    <div className="productHover">
                        <p>En savoir plus +</p>
                    </div>
                </div>
            </Link>
            <div className="productDescr">
                <Link to={product.slug} style={{
                    textDecoration: 'none'
                }}>
                    <p className="productName">
                        {product.productName}
                    </p>
                </Link>
                <h4 className="productPrice">
                    {product.productPrice}€
                </h4>
            </div>
        </div>
    </div>
)
