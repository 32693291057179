import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ProductPreview from '../components/productPreview'

class BoutiqueIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [author] = get(this, 'props.data.allContentfulShopHero.edges')
    const products = get(this, 'props.data.allContentfulShop.edges')

    return (
      <Layout location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} />
          <div className="wrapper">
            {author.node.headline ? (
                <h2 className="section-headline">{author.node.headline}</h2>
            ) : null}
            <ul className="productWrapper">
              {products.map(({ node }) => {
                if(node !== null || typeof node === 'undefined') {
                    return (
                        <ProductPreview product={node} key={node.slug} />
                    )
                } else {
                    return (
                        <p>Désolé, aucun produit n'est disponible pour le moment...</p>
                    )
                }
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BoutiqueIndex

export const pageQuery = graphql`
  query ShopQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulShopHero {
      edges {
        node {
          title
          shortBio {
              shortBio
          }
          headline
          heroImage: headerImage {
            fluid(
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    allContentfulShop {
      edges {
        node {
          productName
          slug
          productPrice
          heroImage: productImages {
            fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: NO_CHANGE) {
                ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
